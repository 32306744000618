/* eslint-disable camelcase */
import Axios from 'axios';
import { getAccessToken, getRefreshToken } from 'app/utilities/Storage';
import cartApis from 'api/cart';
import orderApis from 'api/order';
import offerApis from 'api/offer';
import brandSpaceApis from 'api/brand-space';
import superSaverCouponApis from 'api/coupon';
import brandProductsApis from 'api/brand-products';
import homeNewApis from 'api/home';
import superSavingsApis from 'api/super-savings';
import trendingProductsApis from 'api/trending-products';
import referralsApis from 'api/referrals';
import acknowledgementApis from 'api/Acknowledgement';
import * as ActionsTypes from './ActionTypes';
import * as Actions from './Actions';
import {
  banners, customerAddress, offers, productCategories,
  specialOffers, stores, profile, onlineProducts,
  customerStoreLike, pastProducts, coupon,
  storeAuxiliary, customerStoreReview, notifications, storesSpecificCoupons, unusedOffersCount,
  previousOrderProducts, appExOffer, popularProducts, topPicksShops,
  newlyAddedStores, topSavingOffers, customerStat,
  storeWisePreviousOrderProducts,
  bestRatedStores,
  onlineStores,
  storeCategories,
  seoMetadata,
  categoryProductSearch,
  cartAvailableOffers,
  applyValueOffer,
  referralCode,
  refreshToken,
  brandBanners,
  // premiumStores,
  storeNewCategory,
  customProductSection,
  premiumStoresSOA,
} from '../../api/api';
import { Storage } from '../utilities';
import { isAuthenticated, isGuestUser } from '../utilities/Utils';
import {
  cartLoadActions, cartAddCouponRequest, cartAddOfferRequest, cartCouponDeleteActions,
  cartDeleteActions, cartDeleteOfferRequest, cartDeliveryUpdateRequest,
  cartPaymentUpdateRequest, cartProductActions, cartBulkAddActions, CART_LOAD_REQUEST,
  CART_ADD_COUPONS_REQUEST, CART_ADD_OFFER_REQUEST, CART_DELETE_COUPON_REQUEST,
  CART_DELETE_OFFER_REQUEST, CART_DELETE_REQUEST, CART_DELIVERY_UPDATE_REQUEST,
  CART_PAYMENT_UPDATE_REQUEST, CART_BULK_ADD_LOAD_REQUEST, CART_PRODUCT_REQUEST,
  HIGHLIGHT_CART_OFFER_REQUEST, highlightCartOfferRequest,
} from './cart/actions';
import {
  storeOfferLoadActions, STORE_OFFER_LOAD_REQUEST,
  pastPurchaseItemsActions, PAST_PURCHASE_ITEMS_LOAD_REQUEST,
  maxSavingsActions, MAX_SAVINGS_LOAD_REQUEST,
  bogoOffersActions, BUY_1_GET_1_FREE_LOAD_REQUEST,
  getFreeProductsActions, GET_FREE_PRODUCT_LOAD_REQUEST,
} from './offer/actions';
import {
  brandSpaceLoadActions, BRAND_SPACE_LOAD_REQUEST,
} from './brand-space/actions';
import {
  superSaverCouponsLoadAction, SUPER_SAVER_COUPONS_LOAD_REQUEST,
} from './coupon/actions';
import {
  brandProductsLoadActions, BRAND_PRODUCTS_LOAD_REQUEST,
} from './brand-products/actions';
import {
  homeNewLoadActions, HOME_NEW_LOAD_REQUEST,
} from './home/actions';
import {
  superSavingsLoadActions, SUPER_SAVINGS_LOAD_REQUEST,
} from './super-savings/actions';
import {
  trendingProductsLoadActions, TRENDING_PRODUCTS_LOAD_REQUEST,
} from './trending-products/actions';
import {
  referralsLoadActions, REFERRALS_LOAD_REQUEST,
} from './referrals/actions';
import { ACTIVE_ORDER_LOAD_REQUEST, activeOrderActions } from './orders/action';
import {
  ackLoadActions, ACK_LOAD_REQUEST,
} from './acknowledgement/actions';

const {
  cartLoad, cartProductAdd, cartProductUpdate, cartAddCoupon, cartAddOffer,
  cartDelete, cartDeleteCoupon, cartDeleteOffer, cartDeliveryUpdate,
  cartPaymentUpdate, cartProductBulkAdd, cartProductDelete, highlightCartValueOffer,
  customCartProductDelete, customCartProductUpdate,
} = cartApis;
const {
  storeOffers, gotoItemProducts, getMaxSavingsOffers, getBogoAndComboOffers,
} = offerApis;
const { brandSpaces } = brandSpaceApis;
const { superSaverCoupon } = superSaverCouponApis;
const { brandProducts } = brandProductsApis;
const { homeNew } = homeNewApis;
const { superSavings } = superSavingsApis;
const { trendingProducts } = trendingProductsApis;
const { referrals } = referralsApis;
const { ackBanners } = acknowledgementApis;

const APIMiddleware = (store) => (next) => (action) => {
  const customerId = Storage.getUUID();
  const state = store.getState();
  const { selectedAddress, cartId } = state.main;
  const location = selectedAddress ? `${selectedAddress.location.lat},${selectedAddress.location.lng}` : '19.1207983,72.8782323';

  let onSuccess;
  let onFailure;
  let cancelToken;
  if (action.payload) {
    onSuccess = action.payload.onSuccess;
    onFailure = action.payload.onFailure;
    cancelToken = action.payload.cancelToken;
  }

  const validateRefreshToken = (
    successCallback = () => {},
  ) => {
    const refresh_token = getRefreshToken() || '';
    const access_token = getAccessToken() || '';
    refreshToken(
      'post',
      {
        refresh_token,
        access_token,
        role: 'CUSTOMER',
      },
    ).then(() => {
      successCallback();
    })
      .catch(() => {});
  };

  const getActiveAddresses = (addresses) => (
    addresses.filter((item) => {
      const modifiedAddress = item;
      return modifiedAddress.status !== 'ARCHIVED';
    }).sort((a, b) => {
      if (new Date(a.createdOn).getTime() > new Date(b.createdOn).getTime()) {
        return -1;
      }
      return 1;
    })
  );

  const requestHandler = (handler, actionHandler, data, request, isAborted) => {
    if (isAborted) {
      return;
    }
    if (handler) {
      if (handler.type === 'async') {
        handler.callback(data, () => {
          next(actionHandler(data));
        });
      } else {
        handler.callback(data);
        next(actionHandler(data));
      }
    } else {
      next(actionHandler(data));
    }
  };

  switch (action.type) {
    // case ActionsTypes.ADD_BULK_ADDRESS_REQUEST: {
    //   const { guestUserAddresses } = action.payload;
    //   next(action);
    //   bulkCustomerLocation('post', guestUserAddresses)
    //     .then((res) => {
    //       const data = {
    //         res: res.data.results,
    //       };
    //       requestHandler(
    //         onSuccess,
    //         Actions.addBulkAddressSuccess,
    //         data,
    //       );
    //     })
    //     .catch(() => {
    //       requestHandler(
    //         onFailure,
    //         Actions.addBulkAddressFailure,
    //       );
    //     });
    //   break;
    // }

    case ActionsTypes.ADD_ADDRESS_REQUEST: {
      const { address } = action.payload;
      next(action);
      if (isGuestUser()) {
        try {
          const data = { res: address };
          requestHandler(
            onSuccess,
            Actions.addAddressSuccess,
            data,
          );
        } catch (error) {
          requestHandler(
            onFailure,
            Actions.addAddressFailure,
          );
        }
      } else {
        customerAddress(
          'post',
          address,
        ).then((res) => {
          const data = { res: res.data };
          requestHandler(
            onSuccess,
            Actions.addAddressSuccess,
            data,
          );
        }).catch(() => {
          requestHandler(
            onFailure,
            Actions.addAddressFailure,
          );
        });
      }
      break;
    }

    case ActionsTypes.ADDRESS_LOAD_REQUEST: {
      next(action);
      if (isGuestUser()) {
        try {
          const data = {
            res: getActiveAddresses(Storage.getGuestUserAddresses() || []),
          };
          requestHandler(
            onSuccess,
            Actions.addressLoadSuccess,
            data,
          );
        } catch (error) {
          requestHandler(
            onFailure,
            Actions.addressLoadFailure,
          );
        }
      } else {
        customerAddress('get', null, null, customerId)
          .then((res) => {
            const data = {
              res: getActiveAddresses(res.data.results),
            };
            requestHandler(
              onSuccess,
              Actions.addressLoadSuccess,
              data,
            );
          })
          .catch(() => {
            requestHandler(
              onFailure,
              Actions.addressLoadFailure,
            );
          });
      }
      break;
    }

    case ActionsTypes.RECENT_ADDRESS_REQUEST: {
      next(action);
      if (!isGuestUser()) {
        customerAddress('GET', null, null, customerId, true)
          .then((res) => {
            const data = {
              res: res.data.results,
            };
            requestHandler(
              onSuccess,
              Actions.recentAddressLoadSuccess,
              data,
            );
          }).catch(() => {
            requestHandler(
              onFailure,
              Actions.recentAddressLoadFailure,
            );
          });
      }
      break;
    }

    case ActionsTypes.ADDRESS_CHANGE_REQUEST: {
      const { address } = action.payload;
      address.isDefault = true;
      next(action);
      if (isGuestUser()) {
        try {
          const data = { res: address };
          requestHandler(
            onSuccess,
            Actions.addressChangeSuccess,
            data,
          );
        } catch (error) {
          requestHandler(
            onFailure,
            Actions.addressChangeFailure,
          );
        }
      } else {
        customerAddress('put', address, address.locationId)
          .then((res) => {
            const data = {
              res: res.data,
            };
            requestHandler(
              onSuccess,
              Actions.addressChangeSuccess,
              data,
            );
          })
          .catch(() => {
            requestHandler(
              onFailure,
              Actions.addressChangeFailure,
            );
          });
      }
      break;
    }

    case ActionsTypes.ADDRESS_DELETE_REQUEST: {
      const { address } = action.payload;
      address.status = 'ARCHIVED';
      next(action);
      if (isGuestUser()) {
        try {
          const data = { res: address };
          requestHandler(
            onSuccess,
            Actions.addressDeleteSuccess,
            data,
          );
        } catch (error) {
          requestHandler(
            onFailure,
            Actions.addressDeleteFailure,
          );
        }
      } else {
        customerAddress('delete', null, address.locationId)
          .then(() => {
            const data = {
              res: address,
            };
            requestHandler(
              onSuccess,
              Actions.addressDeleteSuccess,
              data,
            );
          })
          .catch(() => {
            requestHandler(
              onFailure,
              Actions.addressDeleteFailure,
            );
          });
      }
      break;
    }

    case ActionsTypes.ADDRESS_EDIT_REQUEST: {
      const { address } = action.payload;
      next(action);
      if (isGuestUser()) {
        try {
          const data = { res: address };
          requestHandler(
            onSuccess,
            Actions.addressEditSuccess,
            data,
          );
        } catch (error) {
          requestHandler(
            onFailure,
            Actions.addressEditFailure,
          );
        }
      } else {
        customerAddress('put', address, address.locationId)
          .then((res) => {
            const data = {
              res: res.data,
            };
            requestHandler(
              onSuccess,
              Actions.addressEditSuccess,
              data,
            );
          })
          .catch(() => {
            requestHandler(
              onFailure,
              Actions.addressEditFailure,
            );
          });
      }
      break;
    }

    case ActionsTypes.APPEX_OFFER_LOAD_REQUEST: {
      next(action);
      const {
        offset, limit, storeId,
      } = action.payload;
      appExOffer(
        'get',
        offset,
        limit,
        location,
        cancelToken,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.appExOfferLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.appExOfferLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.BOGO_OFFERS_LOAD_REQUEST: {
      next(action);
      const {
        storeId, offset, limit,
      } = action.payload;
      specialOffers(
        'get',
        cancelToken,
        storeId,
        'SPECIAL_BOGO_OFFER',
        offset,
        limit,
        location,
        cartId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.bogoOffersLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.bogoOffersLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.CART_AVAILABLE_OFFERS_REQUEST: {
      next(action);
      const {
        storeId, offset, limit,
      } = action.payload;
      cartAvailableOffers(
        'get',
        cancelToken,
        storeId,
        '',
        offset,
        limit,
        location,
        cartId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.cartAvailableOffersSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.cartAvailableOffersFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.COUPONS_LOAD_REQUEST: {
      next(action);
      const { params = {} } = action.payload;
      coupon(
        'get',
        cancelToken,
        {
          cartId,
          ...params,
        },
      ).then((res) => {
        const data = {
          res: res.data,
          offset: params.offset,
        };
        requestHandler(
          onSuccess,
          Actions.couponsLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.couponsLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.HOME_BANNERS_REQUEST: {
      next(action);
      banners(
        'get',
        cancelToken,
        'HOME_PAGE',
        location,
      ).then((res) => {
        const data = {
          res: res.data.results,
        };
        requestHandler(
          onSuccess,
          Actions.homeBannersSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.homeBannersFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.BRAND_BANNERS_REQUEST: {
      next(action);
      brandBanners(
        'get',
        cancelToken,
        {
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.brandBannersSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.brandBannersFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.HERO_BANNER_REQUEST: {
      next(action);
      banners(
        'get',
        cancelToken,
        'HERO_BANNER',
        location,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.heroBannerSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.heroBannerFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.CUSTOMER_STAT_REQUEST: {
      next(action);
      customerStat(
        'get',
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.customerStatSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          Actions.customerStatFailure,
        );
      });
      break;
    }

    case ActionsTypes.HOME_TOP_BANNERS_REQUEST: {
      next(action);
      banners(
        'get',
        cancelToken,
        'B2C_TOPMOST',
        location,
      ).then((res) => {
        const data = {
          res: res.data.results,
        };
        requestHandler(
          onSuccess,
          Actions.homeTopBannersSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.homeTopBannersFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.INITIAL_LOAD: {
      store.dispatch(Actions.heroBannerRequest(cancelToken));
      store.dispatch(Actions.customerStatRequest(cancelToken));
      store.dispatch(Actions.newlyAddedStoresRequest(cancelToken));
      store.dispatch(Actions.productCategoryRequest(cancelToken));
      store.dispatch(Actions.storesLoadRequest(null, cancelToken));
      store.dispatch(Actions.topSavingOfferRequest(0, 10, null, cancelToken));
      store.dispatch(Actions.brandBannersRequest(cancelToken));
      store.dispatch(Actions.homeBanners(cancelToken));
      store.dispatch(Actions.homeTopBanners(cancelToken));
      store.dispatch(Actions.storesSpecificCouponsRequest(null, null, null, cancelToken));
      store.dispatch(Actions.bogoOffersLoadRequest(0, 4, null, cancelToken));
      store.dispatch(Actions.spendOffersLoadRequest(0, 4, null, cancelToken));
      store.dispatch(Actions.popularProductsRequest(0, 4, null, null, null, null, cancelToken));
      store.dispatch(Actions.appExOfferLoadRequest(0, null, null, null, cancelToken));
      store.dispatch(Actions.topPicksShopsLoadRequest(null, cancelToken));
      store.dispatch(Actions.bestRatedStoresRequest(null, cancelToken));
      store.dispatch(Actions.monthlySamanSectionRequest(0, 10, null, cancelToken));
      store.dispatch(Actions.premiumStoresRequest(cancelToken, 30, 0));
      if (isAuthenticated()) {
        store.dispatch(Actions.previousOrderProductsRequest(null, onSuccess, cancelToken));
        store.dispatch(Actions.unusedOfferCountRequest(true, cancelToken));
      }
      break;
    }

    case ActionsTypes.PREMIUM_STORES_REQUEST: {
      next(action);
      const {
        storeTypeId, filterId,
      } = action.payload;
      const latitude = selectedAddress ? selectedAddress.location.lat : '19.1207983';
      const longitude = selectedAddress ? selectedAddress.location.lng : '72.8782323';
      premiumStoresSOA(
        latitude,
        longitude,
        storeTypeId,
        filterId,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.premiumStoresSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.premiumStoresFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      // premiumStores(
      //   'POST',
      //   location,
      //   cancelToken,
      //   limit,
      //   offset,
      //   params,
      //   filters,
      // ).then((res) => {
      //   const data = {
      //     res: res.data,
      //   };
      //   requestHandler(
      //     onSuccess,
      //     Actions.premiumStoresSuccess,
      //     data,
      //   );
      // }).catch((error) => {
      //   requestHandler(
      //     onFailure,
      //     Actions.premiumStoresFailure,
      //     null,
      //     action.type,
      //     Axios.isCancel(error),
      //   );
      // });
      break;
    }

    case ActionsTypes.STORE_NEW_CATEGORY_REQUEST: {
      next(action);
      storeNewCategory(
        'GET',
        location,
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.storeNewCategorySuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storeNewCategoryFaliure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.NEWLY_ADDED_STORES_REQUEST: {
      next(action);
      const {
        filterFlag, city, locality, storeTypes, limit, offset,
      } = action.payload;
      newlyAddedStores(
        'get',
        filterFlag ? '' : location,
        cancelToken,
        limit,
        offset,
        filterFlag,
        city,
        locality,
        storeTypes,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.newlyAddedStoresSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.newlyAddedStoresFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.NOTIFICATIONS_LOAD_REQUEST: {
      next(action);
      const { offset, limit } = action.payload;
      notifications(
        'get',
        cancelToken,
        offset,
        limit,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.notificationsLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.notificationsLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.OFFERS_LOAD_REQUEST: {
      next(action);
      const { offset, limit, storeId } = action.payload;
      offers(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.offersLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.offersLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.ONLINE_PRODUCTS_REQUEST: {
      next(action);
      const {
        offset, limit, searchText, categoryId, storeId,
      } = action.payload;
      onlineProducts(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
        searchText,
        categoryId,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.onlineProductsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.onlineProductsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.CATEGORY_PRODUCT_SEARCH_REQUEST: {
      next(action);
      const {
        offset, limit, searchText, categoryId, storeId,
      } = action.payload;
      categoryProductSearch(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
        searchText,
        categoryId,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.categoryProductSearchSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.categoryProductSearchFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.POPULAR_PRODUCTS_REQUEST: {
      next(action);
      const {
        offset, limit, searchText, categoryId, storeId,
      } = action.payload;
      popularProducts(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
        searchText,
        categoryId,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.popularProductsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.popularProductsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.ORDER_DETAILS_REQUEST: {
      next(action);
      const {
        orderId,
      } = action.payload;
      orderApis.orderDetails(
        orderId,
        {},
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          Actions.orderDetailsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.orderDetailsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.ORDER_PATCH_REQUEST: {
      next(action);
      const {
        orderId, reason,
      } = action.payload;
      const orderApi = reason === 'ACCEPT' ? orderApis.orderModificationAccept : orderApis.orderModificationReject;
      orderApi(
        orderId,
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          Actions.orderPatchSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          Actions.orderPatchFailure,
          null,
        );
      });
      break;
    }

    case ActionsTypes.ORDERS_CREATE_REQUEST: {
      next(action);
      const {
        cartData = {},
      } = action.payload;
      orderApis.createOrder(
        cartData,
        {},
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          Actions.ordersCreateSuccess,
          data,
        );
      }).catch((error) => {
        const data = {
          res: error.response,
        };
        requestHandler(
          onFailure,
          Actions.ordersCreateFailure,
          data,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.ORDERS_LOAD_REQUEST: {
      next(action);
      const {
        nextUrl,
      } = action.payload;
      orderApis.orderList(
        {},
        nextUrl,
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data,
          nextUrl,
        };
        requestHandler(
          onSuccess,
          Actions.ordersLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.ordersLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.PAST_PRODUCTS_REQUEST: {
      next(action);
      const { offset, limit } = action.payload;
      pastProducts(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.pastProductsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.pastProductsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.PREVIOUS_ORDER_PRODUCTS_REQUEST: {
      next(action);
      const { storeId, deliveredOnGte, deliveredOnLte } = action.payload;
      previousOrderProducts(
        'get',
        cancelToken,
        location,
        storeId,
        cartId,
        deliveredOnGte,
        deliveredOnLte,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.previousOrderProductsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.previousOrderProductsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.PRODUCT_CATEGORY_REQUEST: {
      const { storeId } = action.payload;
      next(action);
      productCategories(
        'get',
        cancelToken,
        location,
        cartId,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data.results,
        };
        requestHandler(
          onSuccess,
          Actions.productCategorySuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.productCategoryFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.STORE_CATEGORIES_REQUEST: {
      next(action);
      storeCategories(
        'get',
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data.results,
        };
        requestHandler(
          onSuccess,
          Actions.storeCategoriesSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storeCategoriesFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.PROFILE_EDIT_REQUEST: {
      next(action);
      profile(
        'patch',
        cancelToken,
        action.payload.profile,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.profileEditSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.profileEditFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.PROFILE_LOAD_REQUEST: {
      next(action);
      profile('get').then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.profileLoadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          Actions.profileLoadFailure,
        );
      });
      break;
    }

    case ActionsTypes.REWARD_LOAD_REQUEST: {
      const { params, nextUrl } = action.payload;
      next(action);
      offerApis.cartRewards(
        params,
        cancelToken,
        nextUrl,
      ).then((res) => {
        const data = {
          res: res.data,
          nextUrl,
        };
        requestHandler(
          onSuccess,
          Actions.rewardLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.rewardLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.STORE_AUXILIARY_REQUEST: {
      const { storeId } = action.payload;
      next(action);
      storeAuxiliary(
        'get',
        cancelToken,
        location,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          storeId,
        };
        requestHandler(
          onSuccess,
          Actions.storeAuxiliarySuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storeAuxiliaryFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.STORES_SPECIFIC_COUPON_REQUEST: {
      next(action);
      const {
        offset, limit, filterFlag, city, locality, storeTypes,
      } = action.payload;
      storesSpecificCoupons(
        'get',
        cancelToken,
        filterFlag ? '' : location,
        offset,
        limit,
        filterFlag,
        city,
        locality,
        storeTypes,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.storesSpecificCouponsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storesSpecificCouponsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.SPEND_OFFERS_LOAD_REQUEST: {
      next(action);
      const {
        storeId, offset, limit,
      } = action.payload;
      specialOffers(
        'get',
        cancelToken,
        storeId,
        'SPECIAL_SPEND_OFFER,SPECIAL_VALUE_OFFER',
        offset,
        limit,
        location,
        cartId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.spendOffersLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.spendOffersLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.STORES_LOAD_REQUEST: {
      next(action);
      const {
        filterFlag, city, locality, storeTypes, limit, offset,
      } = action.payload;
      stores(
        'get',
        cancelToken,
        filterFlag ? '' : location,
        limit,
        offset,
        filterFlag,
        city,
        locality,
        storeTypes,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.storesLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storesLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.ONLINE_STORES_LOAD_REQUEST: {
      next(action);
      const {
        filterFlag, city, locality, storeType, limit, offset,
      } = action.payload;
      onlineStores(
        'get',
        cancelToken,
        filterFlag ? '' : location,
        limit,
        offset,
        filterFlag,
        city,
        locality,
        storeType,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.onlineStoresLoadSuccess,
          data,
        );
      }).catch((error) => {
        if (
          error
          && error.response
          && error.response.status === 410
        ) {
          requestHandler(
            onSuccess,
            Actions.onlineStoresLoadSuccess,
            {
              res: {
                categoryMetaData: {
                  displayName: storeType,
                  description: storeType,
                },
                count: 0,
                hasNext: false,
                hasPrevious: false,
                results: [],
              },
            },
          );
        } else {
          requestHandler(
            onFailure,
            Actions.onlineStoresLoadFailure,
            null,
            action.type,
            Axios.isCancel(error),
          );
        }
      });
      break;
    }

    case ActionsTypes.SEO_METADATA_LOAD_REQUEST: {
      next(action);
      const {
        city, locality, storeType,
      } = action.payload;
      seoMetadata(
        'get',
        cancelToken,
        city,
        locality,
        storeType,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.seoMetadataLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.seoMetadataLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.BEST_RATED_STORES_REQUEST: {
      next(action);
      const {
        filterFlag, city, locality, storeTypes, limit, offset,
      } = action.payload;
      bestRatedStores(
        'get',
        cancelToken,
        filterFlag ? '' : location,
        limit,
        offset,
        filterFlag,
        city,
        locality,
        storeTypes,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.bestRatedStoresSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.bestRatedStoresFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.TOP_PICKS_SHOPS_REQUEST: {
      next(action);
      const {
        filterFlag, city, locality, storeTypes,
      } = action.payload;
      topPicksShops(
        'get',
        filterFlag ? '' : location,
        cancelToken,
        filterFlag,
        city,
        locality,
        storeTypes,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.topPicksShopsLoadSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.topPicksShopsLoadFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.STORE_LIKE_REQUEST: {
      const { storeId, status } = action.payload;
      next(action);
      customerStoreLike(
        'put',
        cancelToken,
        {
          action: status ? 'LIKE' : 'UNLIKE',
          customerId,
          storeId,
        },
      ).then(() => {
        const data = {
          storeId,
          status,
        };
        requestHandler(
          onSuccess,
          Actions.storeLikeSuccess,
          data,
        );
      })
        .catch((error) => {
          requestHandler(
            onFailure,
            Actions.storeLikeFailure,
            null,
            action.type,
            Axios.isCancel(error),
          );
        });
      break;
    }

    case ActionsTypes.STORE_WISE_PREV_ORDER_PRODUCTS_REQUEST: {
      next(action);
      storeWisePreviousOrderProducts(
        'get',
        cancelToken,
        location,
        cartId,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.storeWisePrevOrderProductsSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.storeWisePrevOrderProductsFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.TOP_SAVING_OFFER_REQUEST: {
      next(action);
      const { offset, limit, storeId } = action.payload;
      topSavingOffers(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
        storeId,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.topSavingOfferSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.topSavingOfferFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.MONTHLY_SAMAN_SECTION_REQUEST: {
      next(action);
      const { offset, limit } = action.payload;
      customProductSection(
        'get',
        cancelToken,
        location,
        cartId,
        offset,
        limit,
      ).then((res) => {
        const data = {
          res: res.data,
          offset,
        };
        requestHandler(
          onSuccess,
          Actions.monthlySamanSectionSuccess,
          data,
        );
      }).catch((error) => {
        requestHandler(
          onFailure,
          Actions.monthlySamanSectionFailure,
          null,
          action.type,
          Axios.isCancel(error),
        );
      });
      break;
    }

    case ActionsTypes.UNLOCK_REWARD_REQUEST: {
      next(action);
      offerApis.revealScratchCard(
        action.payload.scratchCardId,
        {},
        null,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.unlockRewardSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          Actions.unlockRewardFailure,
        );
      });
      break;
    }

    case ActionsTypes.UNUSED_OFFER_COUNT_REQUEST: {
      const { onlyGlobal } = action.payload;
      next(action);
      unusedOffersCount(
        'GET',
        cartId,
        location,
        onlyGlobal,
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          Actions.unusedOfferCountSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          Actions.unusedOfferCountFailure,
        );
      });
      break;
    }

    case ActionsTypes.APPLY_VALUE_OFFER_REQUEST:
    {
      next(action);
      const { cId } = action.payload;
      applyValueOffer(
        'post',
        {
          cartId: cId,
        },
      ).then(() => {})
        .catch((error) => {
          requestHandler(
            onFailure,
            Actions.applyValueOfferFailure,
            null,
            action.type,
            Axios.isCancel(error),
          );
        });
      break;
    }

    case ActionsTypes.REFERRAL_CODE_GET_REQUEST: {
      next(action);
      referralCode(
        'get',
        {},
      )
        .then((res) => {
          const data = {
            res: res.data.data,
          };
          requestHandler(
            onSuccess,
            Actions.referralCodeGetSuccess,
            data,
          );
        })
        .catch((error) => {
          let data = null;
          if (error.response
            && error.response.status === 400
            && error.response.data[0]) {
            validateRefreshToken(() => store.dispatch(Actions.referralCodeGetReq()));
            data = {
              error: error.response.data[0].client_message,
            };
          }
          requestHandler(
            onFailure,
            Actions.referralCodeFailure,
            data,
            action.type,
            Axios.isCancel(error),
          );
        });
      break;
    }

    case CART_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      cartLoad(
        {
          ...params,
          location,
          isCheckout: true,
        },
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartLoadActions.loadSuccess,
          data,
        );
        store.dispatch(highlightCartOfferRequest.loadRequest());
      }).catch(() => {
        requestHandler(
          onFailure,
          cartLoadActions.loadFailure,
        );
      });
      break;
    }

    case CART_DELETE_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartDelete(
        body,
        cartId,
        {
          ...params,
          location,
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartDeleteActions.deleteSuccess,
          data,
        );
        store.dispatch(highlightCartOfferRequest.loadRequest());
      }).catch(() => {
        requestHandler(
          onFailure,
          cartDeleteActions.deleteFailure,
        );
      });
      break;
    }

    case CART_PRODUCT_REQUEST: {
      next(action);
      const { data: payload } = action.payload;
      const { actionType, isCustomProduct } = payload;
      let requestBody = {};
      let requestMethod = () => { /* TODO document why this arrow function is empty */ };
      switch (actionType) {
        case 'DELETE':
          if (isCustomProduct) {
            requestBody = {
              customProductId: payload.product.productId,
            };
            requestMethod = customCartProductDelete;
          } else {
            requestBody = {
              retailerProductId: payload.product.productId,
            };
            requestMethod = cartProductDelete;
          }
          break;

        case 'UPDATE':
          if (isCustomProduct) {
            requestBody = {
              customProductId: payload.product.productId,
              quantity: payload.product.quantity,
            };
            requestMethod = customCartProductUpdate;
          } else {
            // Addition of custom product is not happening through middleware
            requestBody = {
              retailerProductId: payload.product.productId,
              quantity: payload.product.quantity,
              isCheckout: true,
            };
            requestMethod = cartProductUpdate;
          }
          break;

        default:
          requestBody = {
            retailerProductId: payload.product.productId,
            quantity: payload.product.quantity,
            isCheckout: true,
          };
          requestMethod = cartProductAdd;
          break;
      }

      if (isGuestUser()) {
        const { cartItems } = state.main;
        let isOtherStoreFound = false;
        if (cartItems && Object.keys(cartItems).length) {
          Object.keys(cartItems).forEach((key) => {
            if (
              cartItems[key]
              && ((cartItems[key].storeId).toString() !== (payload.product.storeId).toString())
            ) {
              isOtherStoreFound = true;
              return;
            }
            isOtherStoreFound = false;
          });
        }
        if (isOtherStoreFound) {
          const data = {
            errorMsg: 'Your cart contains product items from different store. Do you want to clear the cart and add products?',
            productId: payload.product.productId,
            product: payload.product,
            productInfo: payload.product.productInfo,
          };
          requestHandler(
            onFailure,
            Actions.toggleMultiStoreModal,
            data,
          );
          return;
        }
        try {
          const data = {
            product: payload.product,
          };
          requestHandler(
            onSuccess,
            cartProductActions.success,
            data,
          );
        } catch (error) {
          const data = {
            productId: payload.product.productId,
          };
          requestHandler(
            onFailure,
            cartProductActions.failure,
            data,
          );
        }
      } else {
        if (payload.product.productInfo) {
          delete payload.product.productInfo;
        }
        next(action);
        requestMethod(
          requestBody,
          cartId,
          {
            location,
            isCheckout: false,
          },
        )
          .then((res) => {
            store.dispatch(highlightCartOfferRequest.loadRequest());
            const data = {
              res: res.data.data,
              product: payload.product,
              forceAdd: payload.forceAdd,
            };
            requestHandler(
              onSuccess,
              cartProductActions.success,
              data,
            );
          })
          .catch((error) => {
            let data = null;
            if (
              error
              && error.response
              && error.response.status === 400
              && error.response.data
              && error.response.data.errors
              && error.response.data.errors[0].error_code
              && error.response.data.errors[0].error_code === 2001
            ) {
              const { display_text } = error.response.data.errors[0];
              data = {
                errorMsg: `${display_text}`,
                productId: payload.product.productId,
                product: payload.product,
                productInfo: payload.productInfo,
              };
              requestHandler(
                onFailure,
                Actions.toggleMultiStoreModal,
                data,
              );
            } else if (
              error
              && error.response
              && error.response.status === 400
              && error.response.data
              && error.response.data.errors[0]
            ) {
              data = {
                error: error.response.data.errors[0].message,
                productId: payload.product.productId,
              };
              requestHandler(
                onFailure,
                cartProductActions.failure,
                data,
              );
            } else {
              data = {
                productId: payload.product.productId,
              };
            }
            requestHandler(
              onFailure,
              cartProductActions.failure,
              data,
            );
          });
      }
      break;
    }

    case CART_BULK_ADD_LOAD_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartProductBulkAdd(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartBulkAddActions.addSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          cartBulkAddActions.addFailure,
        );
      });
      break;
    }

    case CART_DELIVERY_UPDATE_REQUEST: {
      next(action);
      const { body, params } = action.payload;
      cartDeliveryUpdate(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartDeliveryUpdateRequest.updateSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
              && error.response
              && error.response.status === 400
              && error.response.data
              && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartDeliveryUpdateRequest.updateFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartDeliveryUpdateRequest.updateFailure,
          );
        }
      });
      break;
    }

    case CART_PAYMENT_UPDATE_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartPaymentUpdate(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartPaymentUpdateRequest.updateSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartPaymentUpdateRequest.updateFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartPaymentUpdateRequest.updateFailure,
          );
        }
      });
      break;
    }

    case CART_ADD_OFFER_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartAddOffer(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartAddOfferRequest.addSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartAddOfferRequest.addFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartAddOfferRequest.addFailure,
          );
        }
      });
      break;
    }

    case CART_DELETE_OFFER_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartDeleteOffer(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartDeleteOfferRequest.deleteSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartDeleteOfferRequest.deleteFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartDeleteOfferRequest.deleteFailure,
          );
        }
      });
      break;
    }

    case CART_ADD_COUPONS_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartAddCoupon(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartAddCouponRequest.addSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartAddCouponRequest.addFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartAddCouponRequest.addFailure,
          );
        }
      });
      break;
    }

    case CART_DELETE_COUPON_REQUEST: {
      next(action);
      const { params, body } = action.payload;
      cartDeleteCoupon(
        body,
        cartId,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        store.dispatch(highlightCartOfferRequest.loadRequest());
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          cartCouponDeleteActions.deleteSuccess,
          data,
        );
      }).catch((error) => {
        let data = null;
        if (
          error
          && error.response
          && error.response.status === 400
          && error.response.data
          && error.response.data.errors[0]
        ) {
          data = {
            errorMsg: error.response.data.errors[0].message,
          };
          requestHandler(
            onFailure,
            cartCouponDeleteActions.deleteFailure,
            data,
          );
        } else {
          requestHandler(
            onFailure,
            cartCouponDeleteActions.deleteFailure,
          );
        }
      });
      break;
    }

    case HIGHLIGHT_CART_OFFER_REQUEST: {
      next(action);
      const { params } = action.payload;
      highlightCartValueOffer(
        cartId,
        cancelToken,
        params,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          highlightCartOfferRequest.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          highlightCartOfferRequest.loadFailure,
        );
      });
      break;
    }

    // Offers requests
    case STORE_OFFER_LOAD_REQUEST: {
      next(action);
      const { params, storeCode } = action.payload;
      storeOffers(
        storeCode,
        {
          ...params,
          location,
        },
        cancelToken,
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          storeOfferLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          storeOfferLoadActions.loadFailure,
        );
      });
      break;
    }

    // Past Purchase Items Requests
    case PAST_PURCHASE_ITEMS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      gotoItemProducts(
        cancelToken,
        {
          offset: 0,
          limit: 1000,
          cartId: cartId || '',
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
          ...params,
        },
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          pastPurchaseItemsActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          pastPurchaseItemsActions.loadFailure,
        );
      });
      break;
    }

    // Max Savings
    case MAX_SAVINGS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      getMaxSavingsOffers(
        cancelToken,
        {
          offset: 0,
          limit: 20,
          cartId: cartId || '',
          location,
          ...params,
        },
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          maxSavingsActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          maxSavingsActions.loadFailure,
        );
      });
      break;
    }
    // Brand Spaces
    case BRAND_SPACE_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      brandSpaces(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
          // requestFrom: 'WEB',
          // page: 'OFFERS',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          brandSpaceLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          brandSpaceLoadActions.loadFailure,
        );
      });
      break;
    }

    // BOGO Offers
    case BUY_1_GET_1_FREE_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      getBogoAndComboOffers(
        cancelToken,
        {
          offerType: 'SPECIAL_BOGO_OFFER',
          offset: 0,
          limit: 100,
          getSameBogo: true,
          cartId: cartId || '',
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
          ...params,
        },
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          bogoOffersActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          bogoOffersActions.loadFailure,
        );
      });
      break;
    }

    // GET Free Products
    case GET_FREE_PRODUCT_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      getBogoAndComboOffers(
        cancelToken,
        {
          offerType: 'SPECIAL_SPEND_OFFER,SPECIAL_VALUE_OFFER',
          offset: 0,
          limit: 100,
          cartId: cartId || '',
          location,
          ...params,
        },
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          getFreeProductsActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          getFreeProductsActions.loadFailure,
        );
      });
      break;
    }
    // Brand Products
    case BRAND_PRODUCTS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      brandProducts(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          brandProductsLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          brandProductsLoadActions.loadFailure,
        );
      });
      break;
    }

    // Home New
    case HOME_NEW_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      homeNew(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          homeNewLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          homeNewLoadActions.loadFailure,
        );
      });
      break;
    }

    // Super Saver Coupon
    case SUPER_SAVER_COUPONS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      superSaverCoupon(
        cancelToken,
        {
          ...params,
          // page: 1,
          // limit: 100,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          superSaverCouponsLoadAction.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          superSaverCouponsLoadAction.loadFailure,
        );
      });
      break;
    }

    // Super Savings
    case SUPER_SAVINGS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      superSavings(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
          isFreeSample: true,
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          superSavingsLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          superSavingsLoadActions.loadFailure,
        );
      });
      break;
    }

    // Trending Products
    case TRENDING_PRODUCTS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      trendingProducts(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          trendingProductsLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          trendingProductsLoadActions.loadFailure,
        );
      });
      break;
    }

    // Referrals
    case REFERRALS_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      referrals(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          referralsLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          referralsLoadActions.loadFailure,
        );
      });
      break;
    }

    // Active Orders
    case ACTIVE_ORDER_LOAD_REQUEST: {
      next(action);
      orderApis.activeOrder(
        'GET',
        customerId,
      ).then((res) => {
        const data = {
          res: res.data,
        };
        requestHandler(
          onSuccess,
          activeOrderActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          activeOrderActions.loadFailure,
        );
      });
      break;
    }

    // Acknowledgenent Banners
    case ACK_LOAD_REQUEST: {
      next(action);
      const { params } = action.payload;
      ackBanners(
        cancelToken,
        {
          ...params,
          latitude: selectedAddress ? selectedAddress.location.lat : '19.1207983',
          longitude: selectedAddress ? selectedAddress.location.lng : '72.8782323',
        },
      ).then((res) => {
        const data = {
          res: res.data.data,
        };
        requestHandler(
          onSuccess,
          ackLoadActions.loadSuccess,
          data,
        );
      }).catch(() => {
        requestHandler(
          onFailure,
          ackLoadActions.loadFailure,
        );
      });
      break;
    }

    default:
      next(action);
      break;
  }
};

export default APIMiddleware;

import {
  getToken, getUUID,
} from 'app/utilities/Storage';
import Axios from 'axios';
import * as url from './url';
import {
  getParams, setAuthorizationHeaders, setAuthTokenHeader, setHeaders,
} from './utils';

const uuid = getUUID() || '';

export const userExistance = (method, phoneNumber) => (
  Axios({
    url: url.userExistance(phoneNumber),
    method,
    headers: setHeaders(),
  })
);

export const otp = (method, data) => (
  Axios({
    url: url.otp(),
    method,
    data,
    headers: setHeaders({}, true),
  })
);

export const signUp = (method, data, requestId, Otp) => (
  Axios({
    url: url.signUp(),
    method,
    data,
    headers: setHeaders({
      'Request-Id': requestId,
      Otp,
    }, true),
  })
);

export const loginWithOtp = (method, data, requestId, Otp) => (
  Axios({
    url: url.loginWithOtp(),
    method,
    data,
    headers: setHeaders({
      'Request-Id': requestId,
      Otp,
    }, true),
  })
);

export const profile = (method, cancelToken, data) => (
  Axios({
    url: url.profile(),
    method,
    cancelToken,
    data,
    headers: setHeaders({
      'Content-Type': method === 'patch'
        ? 'multipart/form-data' : 'application/json',
      ...setAuthTokenHeader(),
    }, true),
  })
);

export const customerAddress = (method, data, addressId, customerId, recentAddresses) => {
  const params = getParams({ customerId, recentAddresses });
  return Axios({
    url: url.customerAddress(addressId),
    method,
    data,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
  });
};

export const cart = (method, data, cartId, params = {}) => (
  Axios({
    url: url.cart(cartId),
    method,
    data,
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
  })
);

export const coupon = (method, cancelToken, params = {}) => (
  Axios({
    url: url.coupon(),
    method,
    params: getParams(params),
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
  })
);

export const customerStoreLike = (method, cancelToken, data) => Axios({
  url: url.customerStoreLike(),
  method,
  data,
  headers: setHeaders(setAuthorizationHeaders()),
  cancelToken,
});

export const faqAggregated = (method) => Axios({
  url: url.btocfaqaggregated(),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
});

export const faqHelpUpdate = (method, data) => Axios({
  url: url.faqHelpUpdate(),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  data,
});

export const faqs = (method, categoryId) => Axios({
  url: url.faqs(),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  params: getParams({
    categoryId,
  }),
});

export const favouriteshops = (method, customerId, location) => Axios({
  url: url.favouriteshops(customerId),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  params: getParams({
    location,
  }),
});

export const globalSearch = (
  cancelToken, location, cartId, limit, offset, searchText, storeId,
) => {
  const params = getParams({
    location, cartId, limit, offset, searchText, storeId,
  });
  return Axios({
    url: url.globalSearch(),
    method: 'get',
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const notifications = (
  method, cancelToken, offset, limit,
) => {
  const params = getParams({
    offset, limit,
  });
  return Axios({
    url: url.notifications(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const order = (
  method, orderId, data, cancelToken,
  // eslint-disable-next-line camelcase
  offset, limit, order_by, customerId,
  isFirstOrderView,
) => {
  const params = getParams({
    offset, limit, order_by, customerId, isFirstOrderView,
  });
  return Axios({
    url: url.order(orderId),
    method,
    data,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const onlineProducts = (
  method, cancelToken, location, cartId, offset,
  limit, searchText, categoryId, storeId, productId,
) => {
  const params = getParams({
    location,
    cartId,
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
  });
  return Axios({
    url: url.onlineProducts(productId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const categoryProductSearch = (
  method, cancelToken, location, cartId, offset,
  limit, searchText, categoryId, storeId, productId,
) => {
  const params = getParams({
    location,
    cartId,
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
  });
  return Axios({
    url: url.categoryProductSearch(productId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const popularProducts = (
  method, cancelToken, location, cartId, offset,
  limit, searchText, categoryId, storeId, productId,
) => {
  const params = getParams({
    location,
    cartId,
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
  });
  return Axios({
    url: url.popularProducts(productId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const pastProducts = (
  method, cancelToken, location, cartId, offset, limit,
) => {
  const params = getParams({
    location, cartId, offset, limit,
  });
  return (
    Axios({
      url: url.pastProducts(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const previousOrderProducts = (
  method, cancelToken, location, storeId, cartId,
  deliveredOnGte, deliveredOnLte,
) => {
  const params = getParams({
    location, storeId, cartId, deliveredOnGte, deliveredOnLte,
  });
  return (
    Axios({
      url: url.previousOrderProducts(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const storeWisePreviousOrderProducts = (
  method, cancelToken, location, cartId,
) => {
  const params = getParams({
    location, cartId,
  });
  return (
    Axios({
      url: url.storeWisePreviousOrderProducts(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const productCategories = (
  method, cancelToken, location,
  cartId, storeId,
) => {
  const params = getParams({
    location,
    cartId,
    storeId,
  });
  return Axios({
    url: url.productCategories(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const storeCategories = (
  method, cancelToken,
) => Axios({
  url: url.storeCategories(),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  cancelToken,
});

export const specialOffers = (
  method, cancelToken, storeId, offerType,
  offset, limit, location, cartId,
) => {
  const params = getParams({
    storeId, offerType, offset, limit, location, cartId,
  });
  return Axios({
    url: url.specialOffers(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const cartAvailableOffers = (
  method, cancelToken, storeId, offerType,
  offset, limit, location, cartId,
) => {
  const params = getParams({
    storeId, offerType, offset, limit, location, cartId,
  });
  return Axios({
    url: url.cartAvailableOffers(cartId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const storeAuxiliary = (
  method, cancelToken, location, storeId,
) => {
  const params = getParams({
    location,
  });
  return Axios({
    url: url.storeAuxiliary(storeId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const stores = (
  method, cancelToken, location,
  limit, offset, filterFlag, city, locality, storeTypes,
) => {
  const params = getParams({
    location,
    filterFlag,
    city,
    locality,
    storeTypes,
    limit,
    offset,
  });
  return (
    Axios({
      url: url.stores(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const onlineStores = (
  method, cancelToken, location,
  limit, offset, filterFlag, city, locality, storeType,
) => {
  const params = getParams({
    location,
    filterFlag,
    city,
    locality,
    storeTypes: storeType,
    limit,
    offset,
  });
  return (
    Axios({
      url: url.onlineStores(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const seoMetadata = (
  method, cancelToken, city, locality, storeType,
) => {
  const params = getParams({
    city,
    locality,
    storeType,
  });
  return (
    Axios({
      url: url.seoMetadata(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const bestRatedStores = (
  method, cancelToken, location,
  limit, offset, filterFlag, city, locality, storeTypes,
) => {
  const params = getParams({
    location,
    filterFlag,
    city,
    locality,
    storeTypes,
    limit,
    offset,
    isBestRated: true,
  });
  return (
    Axios({
      url: url.stores(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const getGeoAddress = (latlng) => Axios.get(url.reverseGeoCode(latlng));

export const offers = (
  method, cancelToken, location, cartId, offset, limit, storeId,
) => {
  const params = getParams({
    location, cartId, offset, limit, storeId,
  });
  return (
    Axios({
      url: url.offers(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const banners = (
  method, cancelToken, bannerType, location,
) => {
  const params = getParams({
    banner_type: bannerType,
    location,
  });
  return (
    Axios({
      url: url.banners(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const brandBanners = (
  method, cancelToken, params = {},
) => (
  Axios({
    url: url.brandBanners(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const razorpayOrder = (method, data) => (
  Axios({
    url: url.razorpayOrder(),
    method,
    data,
    headers: setHeaders(setAuthorizationHeaders()),
  })
);

export const razorpayOrderStatus = (method, paymentId) => (
  Axios({
    url: url.razorpayOrderStatus(paymentId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
  })
);

export const customerStoreReview = (method, cancelToken, orderId, data) => Axios({
  url: url.customerStoreReview(orderId),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  data,
  cancelToken,
});

export const truecaller = (method, requestId, cancelToken) => Axios({
  url: url.truecaller(requestId),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  cancelToken,
});

export const eventTracker = (method, data) => Axios({
  url: url.eventTracker(),
  method,
  headers: setHeaders(setAuthorizationHeaders()),
  data,
});

export const rewards = (method, data, params = {}, cancelToken, scratchCardId) => (
  Axios({
    method,
    url: url.rewards(scratchCardId),
    data,
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const referEarnCustomerRegistered = (method) => (
  Axios({
    url: url.referEarnCustomerRegistered(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
  })
);

export const ordertatbreached = (method, customerId) => (
  Axios({
    method,
    url: url.ordertatbreached(customerId),
    headers: setHeaders(setAuthorizationHeaders()),
  })
);

export const webEvents = (
  method, data,
) => (
  Axios({
    method,
    url: url.webEvents(),
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const storesSpecificCoupons = (
  method, cancelToken, location, offset, limit,
  filterFlag, city, locality, storeTypes,
) => {
  const params = getParams({
    location,
    offset,
    limit,
    filterFlag,
    city,
    locality,
    storeTypes,
  });
  return (
    Axios({
      url: url.storesSpecificCoupons(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const unusedOffersCount = (
  method, cartId, location, onlyGlobal, cancelToken,
) => {
  const params = getParams({
    cartId, location, onlyGlobal,
  });
  return (
    Axios({
      url: url.unusedOffersCount(),
      method,
      headers: setHeaders(setAuthTokenHeader()),
      params,
      cancelToken,
    })
  );
};

export const appExOffer = (
  method, offset, limit, location, cancelToken, storeId,
) => {
  const params = getParams({
    limit,
    offset,
    location,
    storeCode: storeId,
  });
  return Axios({
    url: url.appExOffer(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const synctohomedelivery = (
  method, customerId, data,
) => (
  Axios({
    url: url.synctohomedelivery(customerId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const topSavingOffers = (
  method, cancelToken, location, cartId, offset, limit, storeId,
) => {
  const params = getParams({
    location, cartId, offset, limit, storeId,
  });
  return (
    Axios({
      url: url.topSavingOffers(),
      method,
      headers: setHeaders(setAuthorizationHeaders()),
      params,
      cancelToken,
    })
  );
};

export const customProductSection = (
  method, cancelToken, location, cartId, offset, limit,
) => {
  const params = getParams({
    location, cartId, offset, limit,
  });
  return (
    Axios({
      url: url.customProductSection(),
      method,
      headers: setHeaders({
        ...setAuthorizationHeaders(),
        page: 'HOME',
        section: 1,
      }),
      params,
      cancelToken,
    })
  );
};

export const customProductDetail = (
  method, params = {}, cancelToken,
) => (
  Axios({
    url: url.customProductDetail(),
    method,
    headers: setHeaders({
      ...setAuthorizationHeaders(),
      page: 'HOME',
      section: 1,
    }),
    params: getParams(params),
    cancelToken,
  })
);

export const topPicksShops = (
  method, location, cancelToken,
  filterFlag, city, locality, storeTypes,
) => {
  const params = getParams({
    location, filterFlag, city, locality, storeTypes,
  });
  return Axios({
    url: url.topPicksShops(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const deviceInfo = (
  method, customerId, data,
) => (
  Axios({
    url: url.deviceInfo(customerId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const newlyAddedStores = (
  method, location, cancelToken, limit, offset,
  filterFlag, city, locality, storeTypes,
) => (
  Axios({
    url: url.newlyAddedStores(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams({
      location,
      filterFlag,
      city,
      locality,
      storeTypes,
      limit,
      offset,
    }),
    cancelToken,
  })
);

export const customerStat = (
  method, cancelToken,
) => (
  Axios({
    url: url.customerStat(uuid),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
  })
);

export const storeReviewSummary = (
  method, storeCode, cancelToken,
) => (
  Axios({
    url: url.storeReviewSummary(storeCode),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
  })
);

export const popularCityAndLocality = (
  method, params = {},
) => (
  Axios({
    url: url.popularCityAndLocality(),
    method,
    params: getParams(params),
  })
);

export const bulkCustomerLocation = (
  method, data,
) => (
  Axios({
    url: url.bulkCustomerLocation(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const bulkCart = (
  method, data, cartId,
) => (
  Axios({
    url: url.bulkCart(cartId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const orderLocation = (
  method, data, cartId,
) => (
  Axios({
    url: url.orderLocation(cartId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const openPositions = (
  method, data,
) => (
  Axios({
    method,
    url: url.openPositions(),
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const globalSearchSuggestion = (
  method, params = {}, cancelToken,
) => (
  Axios({
    url: url.globalSearchSuggestion(),
    method,
    params: getParams(params),
    cancelToken,
  })
);

export const globalProductSearch = (
  method, params = {}, data, cancelToken,
) => (
  Axios({
    url: url.globalProductSearch(),
    method,
    params: getParams(params),
    cancelToken,
    data,
  })
);

export const globalStoreProductDetails = (
  method, params = {}, productId, cancelToken,
) => (
  Axios({
    url: url.globalStoreProductDetails(productId),
    method,
    params: getParams(params),
    cancelToken,
  })
);

export const storeProducts = (
  method, cancelToken, location, cartId, offset,
  limit, searchText, categoryId, storeId, productId,
) => {
  const params = getParams({
    location,
    cartId,
    offset,
    limit,
    searchText,
    categoryId,
    storeId,
  });
  return Axios({
    url: url.storeProducts(productId),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params,
    cancelToken,
  });
};

export const globalSearchBogoOffer = (
  method, params = {}, data, cancelToken,
) => (
  Axios({
    url: url.globalSearchBogoOffer(),
    method,
    params: getParams(params),
    cancelToken,
    data,
  })
);

export const applyValueOffer = (
  method, data,
) => (
  Axios({
    url: url.applyValueOffer(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const referralCode = (
  method, data,
) => (
  Axios({
    url: url.referralCode(getUUID()),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const refreshToken = (
  method, data,
) => (
  Axios({
    url: url.refreshToken(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const customerStoreReferral = (
  method, data,
) => (
  Axios({
    url: url.customerStoreReferral(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  })
);

export const premiumStores = (
  method, location, cancelToken, limit, offset, params = {}, filter = [],
) => (
  Axios({
    url: url.premiumStores(),
    method,
    headers: getToken()
      ? setHeaders({
        Authorization: `Token ${getToken()}`,
        'Customer-Id': getUUID(),
      })
      : setHeaders({
        Authorization: `Token ${getToken()}`,
        'Customer-Id': process.env.REACT_APP_ENV === 'staging' ? 'f17701c8-f2b1-42cb-97e7-547bab4abce8' : 'bc85393e-0fc4-443e-a97e-18c8fb4790e9',
      }),
    params: getParams({
      location,
      limit,
      offset,
      storeGroupId: '',
      isFreeSample: true,
      ...params,
    }),
    data: { filter, sortBy: [] },
    cancelToken,
  })
);

export const storeNewCategory = (
  method, location, cancelToken, data, customerId,
) => (
  Axios({
    url: url.storeNewCategory(),
    method,
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams({
      location,
      customerId,
    }),
    data,
    cancelToken,
  })
);
export const uploadImage = async (method, data) => (
  Axios({
    method,
    url: url.imageUpload(),
    headers: setHeaders(setAuthorizationHeaders()),
    data,
  }));

export const inFocusToday = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.inFocusToday(),
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const productSearch = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.productSearch(),
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
    params,
  })
);

export const mustTrySearch = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.mustTrySearch(),
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const maxSavingsProducts = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.maxSavingsProducts(),
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const inFocusTodayProducts = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.inFocusTodayProducts(),
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
    cancelToken,
  })
);

export const inStoreProductSearch = (
  method, cancelToken, storeId, params = {},
) => (
  Axios({
    method,
    url: url.inStoreProductSearch(storeId),
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
    params: getParams(params),
  })
);

export const storeSearch = (
  method, cancelToken, params = {},
) => (
  Axios({
    method,
    url: url.storeSearch(),
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
    params: getParams(params),
  })
);

export const recentSearch = (
  method, params = {},
) => (
  Axios({
    method,
    url: url.recentSearch(),
    headers: setHeaders(setAuthorizationHeaders()),
    params: getParams(params),
  })
);

export const newGlobalSearch = (
  method, cancelToken, path = 'all', params = {},
) => (
  Axios({
    method,
    url: url.newGlobalSearch(path),
    headers: setHeaders(setAuthorizationHeaders()),
    cancelToken,
    params: getParams(params),
  })
);

export const createSearchHistory = (
  method, data = {}, params = {},
) => (
  Axios({
    method,
    url: url.createSearchHistory(),
    headers: setHeaders(setAuthorizationHeaders()),
    data,
    params: getParams(params),
  })
);

export const monthlySamaan = (
  params = {},
) => (
  Axios({
    url: url.monthlySamaan(),
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
  })
);

export const servicableStoreCount = (
  params = {},
) => (
  Axios({
    url: url.servicableStoreCountApi(),
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams(params),
  })
);

export const recommendedProducts = (
  context = '', retailerProductId = '', cartId = '',
) => (
  Axios({
    url: url.recommendedProducts(),
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams({
      context,
      retailerProductId,
      cartId,
    }),
  })
);

export const relatedProducts = (
  productId = '', storeId = '',
) => (
  Axios({
    url: url.relatedProducts(),
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams({
      productId,
      storeId,
    }),
  })
);

export const premiumStoresSOA = (
  latitude, longitude, storeTypeId = '', filterId = '',
) => (
  Axios({
    url: url.premiumStoresSOA(),
    method: 'GET',
    headers: setHeaders(setAuthorizationHeaders(), true),
    params: getParams({
      latitude,
      longitude,
      storeTypeId,
      filterId,
    }),
  })
);

export const notifyOOSProducts = (
  productId = '',
) => (
  Axios({
    url: url.notifyOOSProducts(productId),
    method: 'POST',
    headers: setHeaders(setAuthorizationHeaders(), true),
    body: {},
  })
);

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CategoryCard from '../individual-layout/CategoryCard';
import LineShimmer from '../individual-layout/LineShimmer';

const isTablet = window.screen.width >= 768 && window.screen.width < 992;
const isMobile = window.screen.width < 992;

const ShopsCategoryCardShimmer = () => (
  <Container>
    <Row className="align-items-center">
      <Col
        xs={24}
        className={`${
          (isTablet || isMobile) ? '' : 'mt-6 border-right'
        }`}
      >
        {
          (isTablet || isMobile) ? (
            <>
              <LineShimmer />
            </>
          ) : (
            <>
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
              <CategoryCard />
            </>
          )
        }
      </Col>
    </Row>
  </Container>
);

export default ShopsCategoryCardShimmer;
